import React from 'react';
import { Provider } from 'react-redux';
import { configureStore } from '@reduxjs/toolkit';
import rootReducer from './rootReducer';

const createStore = () => configureStore({ reducer: rootReducer });
/* eslint-enable */

// eslint-disable-next-line react/display-name,react/prop-types,func-names
export default function ({ element }) {
  return <Provider store={createStore()}>{element}</Provider>;
}
