import { createSlice } from '@reduxjs/toolkit';

export const alertSlice = createSlice({
  name: 'alert',
  initialState: {
    isShow: false,
    content: '',
    variant: 'danger',
  },
  reducers: {
    toggle: (state, { type, payload }) => {
      return { isShow: true, content: payload.content, variant: payload.variant || state.variant };
    },
    close: (state, { type, payload }) => {
      return { isShow: false, content: '', variant: 'danger' };
    },
  },
});

export const { toggle, close } = alertSlice.actions;

export default alertSlice.reducer;
