import { createSlice } from '@reduxjs/toolkit';

export const subscribeSlice = createSlice({
  name: 'subscribe',
  initialState: {
    isShow: false,
  },
  reducers: {
    toggle: (state, { type, payload }) => {
      return { isShow: true };
    },
    close: (state, { type, payload }) => {
      return { isShow: false };
    },
  },
});

export const { toggle, close } = subscribeSlice.actions;

export default subscribeSlice.reducer;
